import React,{useState, useRef } from "react";
import { Form, Input, Button, InputNumber, Upload, Modal, message, Select, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { onSuccess } from "../../libs/onSuccess";
import CategoryCrudService from "../../services/CategoriesCrud.service";
import { useEffect } from "react";
import config from "../../config";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from "moment";
import { onError } from './../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";


const EditCategory = ({ selectedCategory, ReLoad, handleCancel}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState (false)
  const [shouldSubmit, setShouldSubmit] = useState (true)

  // console.log("selected Category",selectedCategory)

  useEffect(()=>{
    form.setFieldsValue(selectedCategory);
  },[selectedCategory])



  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    setLoading(true);
    try {
      const data = {
        name : values.name
      }
      await CategoryCrudService.update(selectedCategory._id,data);

      onSuccess("Category updated Successfully.");
      ReLoad();
      form.resetFields();
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
    
  };
  
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedCategory.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedCategory.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedCategory.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="name" label="Category Name"
              rules={[{ required: true, message: "Please enter the Category name" }]}
            >
              <Input placeholder="Category Name" />
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmlType="submit">
                  {loading ? <LoadingSpinner/>:"Update"}
                </button>
              </Form.Item>
              <button className="secondary_btn" onClick={handleCancelBtn}>
                Cancel
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EditCategory;
