import React, { useState, useEffect} from "react";
import { Row, Col, Form, Input, Select, Button } from "antd";
import CategoryCrudService from "../../services/CategoriesCrud.service";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const CreateCategory = ({ ReLoad}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        name : values.name
      }
      await CategoryCrudService.add(data);

      setLoading(false);
      onSuccess("Category created Successfully.");
      ReLoad();
      form.resetFields();
    } catch (e) {
      onError(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="name" label="Category Name"
              rules={[{ required: true, message: "Please enter the Category name" }]}
            >
              <Input placeholder="Category Name" />
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmltype="submit" loading={loading}>
                  {
                    loading? <LoadingSpinner/>:
                    "Create"
                  }
                </button>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateCategory;