import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch} from "react-redux";
import {setLoginUser} from "../../../Store/Auth/authSlice";
import {deleteCookie, setCookie} from "../../../libs/cookie";
import {onError} from "../../../libs/errorLib";
import AuthService from '../../../services/auth.service';
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";

const Register = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const onFinish = async (values) => {
        try {
            deleteCookie("access_token")
            deleteCookie("refresh_token")
            const {email, password, confirmPassword} = values
            setIsLoading(true);
            let data = {
                // grant_type: "password",
                email,
                password,
                confirmPassword,
                "role":"admin"
            };
            const result = await AuthService.register(data)
            // console.log("register==> ",result)
            dispatch(setLoginUser(result));
            // setCookie("access_token", result.access_token)
            // setCookie("refresh_token", result.refresh_token)

             // Set the cookie expiration date
             const accessExpires = new Date(result.token.access.expires);
             const refreshExpires = new Date(result.token.refresh.expires);
             const accessToken = `access_token=${result.token.access.token}; expires=${accessExpires.toUTCString()}; path=/`;
             document.cookie = accessToken;
             const refreshToken = `refresh_token=${result.token.refresh.token}; expires=${refreshExpires.toUTCString()}; path=/`;
             document.cookie = refreshToken;
        } catch (e) {
            setIsLoading(false);
            onError(e)
        }
    }
    const onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    };
    return (
        <Form
            name="basic"
            labelCol={{
                offset:1,
                span: 7,
            }}
            wrapperCol={{
                span: 12,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        type: "email",
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <button className="primary_btn" 
                // htmlType="submit"
                >
                    {isLoading?
                        <LoadingSpinner/>:"Register"
                    }
                </button>
            </Form.Item>
        </Form>
    );
};

export default Register;
