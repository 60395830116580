import React,{useState, useRef } from "react";
import { Form, Input, Button, InputNumber, Upload, Modal, message, Select, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { onSuccess } from "../../libs/onSuccess";
import BlogsCategoryCrudService from "../../services/BlogsCategoriesCrud.service";
import { useEffect } from "react";
import config from "../../config";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from "moment";
import { onError } from '../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";


const EditBlogsCategory = ({ selectedBlogsCategory, ReLoad, handleCancel}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState (false)
  const [shouldSubmit, setShouldSubmit] = useState (true)

  console.log("selected Blogs Category",selectedBlogsCategory)

  useEffect(()=>{
    form.setFieldsValue(selectedBlogsCategory);
  },[selectedBlogsCategory])



  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    setLoading(true);
    try {
      const data = {
        name : values.name,
        seoTitle : values.seoTitle,
        seoDescription : values.seoDescription,
        seoKeywords : values.seoKeywords,
        permalink : values.permalink,
      }
      await BlogsCategoryCrudService.update(selectedBlogsCategory.id,data);

      onSuccess("Blogs Category updated Successfully.");
      ReLoad();
      form.resetFields();
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
    
  };
  
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedBlogsCategory?.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedBlogsCategory?.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedBlogsCategory?.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="name" label="Name"
              rules={[{ required: true, message: "Please enter the name" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item name="seoTitle" label="SEO Title"
              rules={[{ required: true, message: "Please enter the SEO title" }]}
            >
              <Input placeholder="SEO Title" />
            </Form.Item>
            
            <Form.Item name="seoDescription" label="SEO Description"
              rules={[{ required: true, message: "Please enter the SEO description" }]}
            >
              <Input placeholder="SEO Description" />
            </Form.Item>
            
            <Form.Item name="seoKeywords" label="SEO Keywords"
              rules={[{ required: true, message: "Please enter the SEO keywords" }]}
            >
              <Input placeholder="SEO Keywords" />
            </Form.Item>
            
            <Form.Item name="permalink" label="Perma Link"
              rules={[{ required: true, message: "Please enter the perma link" }]}
            >
              <Input placeholder="Perma Link" />
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmlType="submit">
                  {loading ? <LoadingSpinner/>:"Update"}
                </button>
              </Form.Item>
              <button className="secondary_btn" onClick={handleCancelBtn}>
                Cancel
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EditBlogsCategory;
