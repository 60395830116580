import React, { useEffect, useState , useRef} from "react";
import { Form, Input, Button, Select, Upload,Modal } from "antd";
import { onSuccess } from "../../libs/onSuccess";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../config";
import BlogsCrudService from "../../services/BlogsCrud.service";
import { onError } from "../../libs/errorLib";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import ReactQuill from "react-quill";
import moment from "moment";
import { useSelector } from 'react-redux';
import Tinymce from "../../Components/Tinymce";
const { Option } = Select;

const EditBlog = ({ selectedBlog, ReLoad, handleCancel }) => {
  const [loading, setLoading] = useState(false)
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const [imageFileList, setImageFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [description, setDescription] = useState("");
  // console.log("selected Blog", selectedBlog)
  const [shouldSubmit, setShouldSubmit] = useState(true);
  const blogsCategoriesList = useSelector((state)=> state.blogsCategories.data )
  const [relatedProducts, setRelatedProducts] = useState(selectedBlog.related);
  const allProductsList = useSelector((state)=> state.products.data )
  
  console.log("selected Blog", selectedBlog)


  useEffect(() => {
    form.setFieldsValue(selectedBlog);
    setDescription(selectedBlog.description)
    const { image } = selectedBlog;
  
    if (image) {
      const newFile = {
        uid: Date.now(),
        name: image,
        status: "done",
        path: image,
        url: config.IMAGE_BASE_URL + image,
      };
      
      setImageFileList([newFile]);
    } else {
      setImageFileList([]);
    }
  }, [selectedBlog]);


  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    if(imageFileList.length){
      setLoading(true)
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("permalink", values.permalink);
        formData.append("description", description);
        formData.append("seoTitle", values.seoTitle)
        formData.append("seoDescription", values.seoDescription)
        formData.append("seoKeywords", values.seoKeywords)
        formData.append("categoryId", values.categoryId)
        
        if (imageFileList && imageFileList.length && imageFileList[0].originFileObj) {
          // console.log("imageFileList[0]",imageFileList[0])
          formData.append("image", imageFileList[0].originFileObj);
        }

        if(values.related){
          values.related.forEach((value, index) => {
            formData.append(`related[${index}]`, value);
          });
        }else{
          formData.append(`related[]`, null);
        }

        await BlogsCrudService.update(selectedBlog.id, formData);

        onSuccess("Blog updated successfully!");
        setLoading(false)
        setImageFileList([]);
        
        ReLoad()
      } catch (error) {
        console.error("Error updating product:", error);
        setLoading(false)
        // Handle error with an appropriate error message
      }
    } else{
      if(!imageFileList.length){
        onError("Image is Required")
      }
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // console.log("file List", imageFileList)
  };

  const handlePreviewCancel = () => setPreviewVisible(false);

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedBlog.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedBlog.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedBlog.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Form form={form} layout="vertical" name="editProductForm" onFinish={onFinish} initialValues={selectedBlog}>

        <div className="btns_group">
          <Form.Item label="Image" name="image">
            <Upload
              required
              listType="picture-card"
              fileList={imageFileList}
              onPreview={handlePreview}
              onChange={handleImageChange}
              beforeUpload={() => false} // Prevent default file upload
            >
              {imageFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </div>

        <Form.Item name="name" label="Title"
          rules={[{ required: true, message: "Please enter the title" }]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        
        <Form.Item
          name="categoryId"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select a category">
            {blogsCategoriesList?.map((category) => (
              <Option key={category.id} value={category.id} 
              selected= {category.id == selectedBlog.categoryId}
              >
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        <Form.Item name="related" label="Related Products">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            initialValues={relatedProducts}

            // onChange={handleChange}
            options={allProductsList.map(product=>{
              return {label:product.name,value:product.id}
            })}
          />
        </Form.Item>
        <Tinymce   setDescription={(desc)=>setDescription(desc)} description={description}/>


        {/* <ReactQuill
          value={description}
          placeholder="write description here..."
          onChange={(value) => {
            setDescription(value);
          }}
        /> */}

        <Form.Item name="seoTitle" label="SEO Title"
          rules={[{ required: true, message: "Please enter the SEO Title" }]}
        >
          <Input placeholder="SEO Title" />
        </Form.Item>

        <Form.Item name="seoDescription" label="SEO Description"
          rules={[{ required: true, message: "Please enter the SEO Description" }]}
        >
          <Input placeholder="SEO Description" />
        </Form.Item>

        <Form.Item name="seoKeywords" label="SEO Keywords"
          rules={[{ required: true, message: "Please enter the SEO Keywords" }]}
        >
          <Input placeholder="SEO Keywords" />
        </Form.Item>

        <Form.Item name="permalink" label="Perma Link"
          rules={[{ required: true, message: "Please enter the perma link" }]}
        >
          <Input placeholder="Perma Link" />
        </Form.Item>

                
        <div className="btns_group">
          <Form.Item>
            <button className="primary_btn" htmlType="submit">
              {loading ? <LoadingSpinner/>:"Update"}
            </button>
          </Form.Item>
          <button className="secondary_btn" onClick={handleCancelBtn}>
            Cancel
          </button>
        </div>
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditBlog;