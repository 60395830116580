import React, {useState} from "react";
import LoginCard from "../../Components/Card/Card";
import Login from "./Login/login";
import Register from "./Register/Register";

const Index = () => {
const [isRegister,setIsRegister]= useState(false);

return (
  <LoginCard title="Publish Packaging | Login">
    {
      isRegister ?
        <Register/>
        :
        <Login />
    }
    {/* <button className="secondary_btn" onClick={()=>setIsRegister(!isRegister)}>
    {!isRegister ? "Register yourself" : "Back to Login"}</button> */}
  </LoginCard>
)};
export default Index;
