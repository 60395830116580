import axios from './../libs/axios';

class AuthService {
    constructor() {
        this.contentType='application/json'
    }

    register(payload) {
        return axios.post(`/auth/register`, payload,{
            headers:{
                'Content-Type':this.contentType
            }
        });
    }
    login(payload) {
        return axios.post(`auth/login`, payload,{
            headers:{
                'Content-Type':this.contentType
            }
        });
    }
    getAccessToken(payload){
        return axios.post(`oauth/token`, payload,{
            headers:{
                'Content-Type':this.contentType
            }
        });
    } 
}

export default new AuthService();
