import AdminLayout from "./Containers/Layout/AdminLayout";
import "./App.css";
import "react-quill/dist/quill.snow.css";


function App() {
  
  return (
    <div className="App">
      <AdminLayout />
    </div>
  );
}

export default App;
