import axios from '../libs/axios';

class SubCategoriesCrudService {
    constructor() {
        this.basePath=`/quote`
    }

    all() {
        return axios.get(`${this.basePath}`,{
        });
    }
    
}

export default new SubCategoriesCrudService();
