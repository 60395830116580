import React,{useState} from "react";
import CategoryCrudService from "../../services/CategoriesCrud.service";
import config from "../../config";
import { onError } from '../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteCategory = ({ selectedCategory, ReLoad, handleCancel }) => {
  const [loading, setLoading]= useState(false)
  const handleDelete = async () => {
    setLoading(true)
    try {
      await CategoryCrudService.delete(selectedCategory._id);
      setLoading(false)
      ReLoad(); // Call the onSuccess callback to close the modal and refresh the product list
      onSuccess("Category deleted successfully!");
    } catch (error) {
      // console.error("Error deleting product:", error);
      setLoading(false)
      onError("An error occurred while deleting the Category. Please try again later.");
    }
  };

  return (
    <div className="delete_main">
      <div>
        {/* <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedCategory.images[0]} alt={selectedCategory.name}/>
        </div> */}
        <p>Are you sure you want to delete the Category "{selectedCategory.name}"?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {
            loading? <LoadingSpinner/>:"Confirm Delete"
          }
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteCategory;