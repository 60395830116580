import {Layout} from "antd";
import Navbar from "../Containers/Layout/Navbar/Navbar";
import Sidebar from "../Containers/Layout/Sidebar/Sidebar";
import React from "react";
import MediaQuery from 'react-responsive';


const {Header, Sider, Content} = Layout;
const MainLayout = ({children}) => {

    return (
        <Layout style={{maxHeight: "100vh", overflow: "hidden"}}>
            <Navbar/>
            <Layout>
                <MediaQuery minWidth={700}>
                    <Sider width={200}>
                        <Sidebar/>
                    </Sider>
                </MediaQuery>
                <Content>
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}
export default MainLayout