import axios from '../libs/axios';

class CategoriesCrudService {
    constructor() {
        this.basePath=`/blog`
    }
    add(payload){
        // const contentType='application/notice.create.v1+json'
        return axios.post(`${this.basePath}`, payload,{
            // headers:{
            //     'Content-Type':contentType
            // }
        });
    }
    view(id){
        return axios.get(`${this.basePath}/${id}`, {});
    }
    update(id,payload) {
        // const contentType = 'application/notice.v1+json'
        return axios.patch(`${this.basePath}/${id}`, payload,{
            // headers:{
            //     'Content-Type':contentType
            // }
        });
    }
    delete(id) {
        return axios.delete(`${this.basePath}/${id}`);
    }
    all() {
        return axios.get(`${this.basePath}`,{
        });
    }
    popular(id, payload){
        return axios.patch(`${this.basePath}/markPopular/${id}`, payload, {
        });
    }
}

export default new CategoriesCrudService();
