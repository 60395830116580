import React from "react";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import store from "./Store/store";
import {BrowserRouter as Router} from "react-router-dom"
import config from "./config";

axios.defaults.baseURL = config.API_BASE_URL;


const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
        <Router>
            <ToastContainer theme="dark"/>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App/>
                </PersistGate>
            </Provider>
        </Router>
);
