import {Drawer} from 'antd';
import React,{useState} from 'react';
import MediaQuery from 'react-responsive';
import Sider from '../Sidebar/Sidebar';
import *as AiIcons from 'react-icons/ai';
import Logo from "../../../assets/images/logo.png"
import "./Navbar.css";

const Navbar = () => {

  const [open, setOpen] = useState(true);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className='navbar'>
      <div className='nav_logo'>
        <img src={Logo} alt="Publish packaging"/>
      </div>
    <MediaQuery maxWidth={700}>
      <AiIcons.AiOutlineMenu onClick={showDrawer}/>
      <Drawer
        title={
          <div className='drawer_title'>
            <div className='drawer_logo'>
              <img src={Logo} alt="Publish packaging"/>
            </div>
            <p onClick={onClose}> <span><AiIcons.AiOutlineClose/></span></p>
          </div>
        }
        placement='left'
        width={320} 
        closable={false} 
        onClose={onClose} 
        open={open}
        >
        <Sider/>
      </Drawer>
      </MediaQuery>
    </div>
  )
}

export default Navbar