import React from "react";
import {Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";

export default function AuthenticatedRoute({children, ...rest}) {
    const {isLoggedIn} = useSelector(({auth}) => auth);
    return (
        <Route {...rest}>
            {isLoggedIn === false ? (
                <Redirect to={`/`}/>
            ) : (
                children
            )}
        </Route>
    );
}