import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/authSlice";
import productsReducer from './Products/ProductsSlice';
import subCategoriesReducer from './SubCategories/SubCategoriesSlice';
import categoriesReducer from './Categories/CategoriesSlice';
import blogsCategoriesReducer from './BlogsCategories/BlogsCategoriesSlice';
import bannersReducer from './Banners/BannersSlice';
import blogsReducer from './Blogs/BlogsSlice';
import quotationsReducer from './Quotations/QuotationsSlice';
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers } from "@reduxjs/toolkit";
import QuotationsSlice from './Quotations/QuotationsSlice';

const persistConfiguration = {
  key: "persist-key",
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  subCategories: subCategoriesReducer,
  categories: categoriesReducer,
  banners: bannersReducer,
  blogsCategories: blogsCategoriesReducer,
  blogs: blogsReducer,
  quotations: quotationsReducer
});

const persistedReducer = persistReducer(persistConfiguration, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export default store;
