import React, { useState, useEffect} from "react";
import { Row, Col, Form, Input, Select, Button, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import BlogsCrudService from "../../services/BlogsCrud.service";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
// import ReactQuill from "react-quill";

import {Editor} from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';

import { useSelector } from 'react-redux';
import Tinymce from "../../Components/Tinymce";
const { Option } = Select;

const CreateBlog = ({ ReLoad}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);
  const [description, setDescription] = useState([]);
  const blogsCategoriesList = useSelector((state)=> state.blogsCategories.data )
  const allProductsList = useSelector((state)=> state.products.data )


  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
        formData.append("image", imageFileList[0].originFileObj)
        formData.append("name", values.name)
        formData.append("permalink", values.permalink)
        formData.append("description", description)
        formData.append("seoTitle", values.seoTitle)
        formData.append("seoDescription", values.seoDescription)
        formData.append("seoKeywords", values.seoKeywords)
        formData.append("categoryId", values.categoryId)
        if(values.related){
          values?.related.forEach((value, index) => {
            formData.append(`related[${index}]`, value);
          });
        }

      await BlogsCrudService.add(formData);

      setLoading(false);
      onSuccess("Blog created Successfully.");
      ReLoad();
      form.resetFields();
      setImageFileList([])
    } catch (e) {
      onError(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="btns_group">
              <Form.Item label="Image">
                <Upload
                  listType="picture-card"
                  fileList={imageFileList}
                  onPreview={handlePreview}
                  onChange={handleImageChange}
                  beforeUpload={() => false} // Prevent default file upload
                >
                  {imageFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </div>
            <Form.Item name="name" label="Title"
              rules={[{ required: true, message: "Please enter the title" }]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            
            <Form.Item
              name="categoryId"
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Select a category">
                {blogsCategoriesList?.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="related" label="Related Products">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Please select"
                // defaultValue={['a10', 'c12']}
                // onChange={handleChange}
                options={allProductsList.map(product=>{
                  return {label:product.name,value:product.id}
                })}
              />
            </Form.Item>

            {/* <ReactQuill
              value={description}
              placeholder="write description here..."
              onChange={(value) => {
                setDescription(value);
              }}
            /> */}
            <Tinymce   setDescription={(desc)=>setDescription(desc)} />
            {/* <CKEditor
              editor={Editor}
              data=""
              onReady={ editor => {
                  console.log( 'Editor is ready to use!', editor );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  console.log( { event, editor, data } );
                  setDescription(data)
              } }
              onBlur={ ( event, editor ) => {
                  console.log( 'Blur.', editor );
              } }
              onFocus={ ( event, editor ) => {
                  console.log( 'Focus.', editor );
              } }
            /> */}

            <Form.Item name="seoTitle" label="SEO Title"
              rules={[{ required: true, message: "Please enter the SEO Title" }]}
            >
              <Input placeholder="SEO Title" />
            </Form.Item>

            <Form.Item name="seoDescription" label="SEO Description"
              rules={[{ required: true, message: "Please enter the SEO Description" }]}
            >
              <Input placeholder="SEO Description" />
            </Form.Item>

            <Form.Item name="seoKeywords" label="SEO Keywords"
              rules={[{ required: true, message: "Please enter the SEO Keywords" }]}
            >
              <Input placeholder="SEO Keywords" />
            </Form.Item>

            <Form.Item name="permalink" label="Perma Link"
              rules={[{ required: true, message: "Please enter the perma link" }]}
            >
              <Input placeholder="Perma Link" />
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmltype="submit" loading={loading}>
                  {
                    loading? <LoadingSpinner/>:
                    "Create"
                  }
                </button>
              </Form.Item>
            </div>
          </Form>
          <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default CreateBlog;