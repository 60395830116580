import React, { useEffect, useState, useRef } from "react";
import { Table, Input, Button, Space, Modal,Row, Col } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SubCategoriesCrudService from "../../services/SubCategoriesCrud.service";
import { setSubCategories } from "../../Store/SubCategories/SubCategoriesSlice";
import CategoriesCrudService from "../../services/CategoriesCrud.service";
import { setCategories } from "../../Store/Categories/CategoriesSlice";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import EditCategories from './EditSubCategories';
import DeleteCategories from './DeleteSubCategories';
import CreateCategories from './CreateSubCategories';
import moment from "moment";
import { useDispatch } from 'react-redux';
import "./SubCategories.css"
import HTMLReactParser from "html-react-parser";
const SubCategories = () => {
  const dispatch = useDispatch();
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [related,setRelated]=useState([])
  const searchInput = useRef(null);

  // Modals
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  useEffect(() => {
    getallSubCategoriesList();
    getallCategoriesList();
  }, []);

  const getallSubCategoriesList = async () => {
    setLoading(true);
    try {
      const response = await SubCategoriesCrudService.all();
      // console.log("allSubCategories", response);
      setAllSubCategories(response.subcategories);
      dispatch(setSubCategories(response.subcategories))
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error);
    }
  };

  const getallCategoriesList = async () => {
    setLoading(true);
    try {
      const response = await CategoriesCrudService.all();
      // console.log("allCategories", response);
      setAllCategories(response.categories);
      dispatch(setCategories(response.categories))
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => (clearFilters && handleReset(clearFilters), handleSearch(selectedKeys, confirm, dataIndex))}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit =async (record) => {
    const response = await SubCategoriesCrudService.getOne(record._id);
    setRelated(response.related)
    setSelectedSubCategory(record);
    setEditModalVisible(true);
  };

  const handleDelete = (record) => {
    setSelectedSubCategory(record);
    setDeleteModalVisible(true);
  };

  const isHotSubmit = async (record) => {
    var data={'isHot' : !record.isHot}
    try {
      const response = await SubCategoriesCrudService.hot(record._id, data);
      getallSubCategoriesList()
      // console.log("allSubCategories", response);
    } catch (error) {
      onError(error);
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={config.IMAGE_BASE_URL + image} alt={image} style={{ maxWidth: "100px" }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name), // Add sorter for the "Name" column
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => {
        return (
          <div title={description}>
            {description.length > 30
              ? `${description.slice(0, 27)}...`
              : description}
          </div>
        );
      }
    },
    {
      title: "Category Name",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (categoryId) => {
        const category = allCategories.find((item) => item._id === categoryId);
        return category ? category.name : "";
      },
    },
    {
      title: "Hot",
      dataIndex: "isHot",
      key: "isHot",
      render: (isHot, record) => {
        return <input onChange={()=>isHotSubmit(record)} type="checkbox" checked={record.isHot}/>
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <EditOutlined title="Edit" onClick={() => handleEdit(record)}/>
          <DeleteOutlined title="Delete" onClick={() => handleDelete(record)}/>
        </Space>
      ),
    },
  ];


// create product modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Row className="page_title">
        <h2>Sub Categories</h2>
        <div className="btns_group">
          <Button className="primary_btn"  onClick={getallSubCategoriesList} loading= {loading} >Refresh</Button>
          <button className="primary_btn"  onClick={showModal}>create Sub Category</button>
        </div>
        <Modal title="Create Sub Category"  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer="">
          <CreateCategories
          subcategories={allSubCategories}
            ReLoad={() => {
                setIsModalOpen(false);
                getallSubCategoriesList(); 
              }}
          />
        </Modal>
      </Row>
      <Table
        dataSource={allSubCategories}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Edit Sub Category"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        {/* Edit form */}
        {selectedSubCategory && (
          <EditCategories
          subcategories={allSubCategories}
            selectedSubCategory={selectedSubCategory}
            related={related}
            ReLoad={() => {
              setEditModalVisible(false);
              getallSubCategoriesList(); // Refresh the product list after editing
            }}
            handleCancel = {() => setEditModalVisible(false)}
          />
        )}
      </Modal>
      <Modal
        title="Delete Sub Category"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={null}
      >
        {/* Delete confirmation */}
        {selectedSubCategory && (
          <DeleteCategories
            selectedSubCategory={selectedSubCategory}
            ReLoad={() => {
              setDeleteModalVisible(false);
              getallSubCategoriesList(); // Refresh the product list after deleting
            }}
            handleCancel = {() => setDeleteModalVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default SubCategories;