import React from 'react'
import { Table, Button } from 'antd';
import * as XLSX from 'xlsx';

export const ExportToExcel = ({ dataSource, columns, fileName }) => {
    const exportToExcel = () => {
      const data = dataSource?.map((record) =>
        columns.map((column) => record[column.dataIndex])
      );
  
      const ws = XLSX.utils.aoa_to_sheet([columns.map((column) => column.title), ...data]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
  
    return (
      <>
        <Button type="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </>
    );
  };