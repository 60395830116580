import React,{useState} from "react";
import ProductCrudService from "../../services/ProductCrud.service";
import config from "../../config";
import { onError } from './../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteProduct = ({ selectedProduct, ReLoad, handleCancel }) => {

  const [loading, setLoading]= useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      await ProductCrudService.delete(selectedProduct.id);
      ReLoad(); // Call the onSuccess callback to close the modal and refresh the product list
      onSuccess("Product deleted successfully!");
      setLoading(false)
    } catch (error) {
      // console.error("Error deleting product:", error);
      onError("An error occurred while deleting the product. Please try again later.");
      setLoading(false)
    }
  };

  return (
    <div className="delete_main">
      <div>
        <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedProduct.images[0]} alt={selectedProduct.name}/>
        </div>
        <p>Are you sure you want to delete the product "{selectedProduct.name}"?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {loading? <LoadingSpinner/>:"Confirm Delete"}
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteProduct;