import React from "react";
import {Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";


export default function UnauthenticatedRoute({children, ...rest}) {
    const {isLoggedIn} = useSelector(({auth}) => auth);
    return (
        <Route {...rest}>
            {isLoggedIn === false && children}
            {isLoggedIn && (
                <Redirect
                    // to={"/dashboard" }
                    to={"/products" }
                />
            )}
        </Route>
    );
}
