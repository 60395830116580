import React, { useState, useRef, useEffect} from "react";
import { Row, Col, Form, Input, Button, Modal, Upload, message, Select } from "antd";
import ProductCrudService from "../../services/ProductCrud.service";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import config from "../../config";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import TinymceEditor from "../../Components/Tinymce";
const { Option } = Select; // Destructure Option from Select

const ProductForm = ({ ReLoad,products}) => {
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const subCategoriesList = useSelector(({ subCategories }) => subCategories.data);

  const handlePreviewCancel = () => setPreviewVisible(false);
  
  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const onFileUpload = async (options) => {
    const { file, onProgress, onSuccess, onError } = options;

    // console.log("filee product", file)
    try {
      const response = await ProductCrudService.upload({ image: file });
      onProgress({ percent: 100 });
      onSuccess();
      const newFile = {
        uid: Date.now(),
        name: file.name,
        status: 'done',
        path: response.filename,
        url: config.IMAGE_BASE_URL + response.filename,
      };
      handleFileChange([...fileList, newFile]);
    } catch (e) {
      onError(e);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (fileList.length > 5) {
        message.error("You can upload a maximum of 5 images.");
        return;
      }

      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("images[]", file.path);
      });

      formData.append("name", values.name);
      formData.append("permalink", values.permalink);
      formData.append("schemaHeader", values.schemaHeader);
      formData.append("description", values.description);
      // formData.append("description", values.description);
      formData.append("additionalDescription", additionalDescription);
      formData.append("seoTitle", values.seoTitle);
      formData.append("seoDescription", values.seoDescription);
      formData.append("seoKeywords", values.seoKeywords);
      formData.append("categoryId", values.categoryId);
      if(values.related){
        values?.related.forEach((value, index) => {
          formData.append(`related[${index}]`, value);
        });
      }
      await ProductCrudService.add(formData);

      onSuccess("Product created Successfully.");
      setFileList([]);
      if (reactQuillRef.current) {
        reactQuillRef.current.getEditor().setContents([]);
      }
      ReLoad();
      form.resetFields();
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="name" label="Product Name"
              rules={[{ required: true, message: "Please enter the product name" }]}
            >
              <Input placeholder="Product Name" />
            </Form.Item>

            <Form.Item name="description" label="Description"
              rules={[{ required: true, message: "Please enter the description" }]}
            >
              <Input placeholder="Description" />
            </Form.Item>
            {/* <Form.Item name="description" label="Description">
              <Input.TextArea placeholder="Description" style={{width:"100%"}}/>
            </Form.Item> */}
            
            <ReactQuill
              ref={reactQuillRef}
              placeholder="write additional description here..."
              onChange={(value, delta, source, editor) => {
                setAdditionalDescription(editor.getHTML);
              }}
            />
                       
            <Form.Item
              name="categoryId"
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Select a category">
                {subCategoriesList?.map((category) => (
                  <Option key={category._id} value={category._id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="schemaHeader" label="Schema Header"
              rules={[{ required: false, message: "Please enter the schema header" }]}
            >
              <Input placeholder="Schema Header" />
            </Form.Item>
            <Form.Item name="permalink" label="Perma Link"
              rules={[{ required: true, message: "Please enter the perma link" }]}
            >
              <Input placeholder="Perma Link" />
            </Form.Item>
            <Form.Item name="seoTitle" label="SEO Title">
              <Input placeholder="SEO Title" />
            </Form.Item>
            <Form.Item name="seoDescription" label="SEO Description">
              <Input.TextArea placeholder="SEO Description" />
            </Form.Item>
            <Form.Item name="seoKeywords" label="SEO Keywords">
              <Input placeholder="SEO Keywords" />
            </Form.Item>
            <Form.Item name="related" label="Related Products">
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              // defaultValue={['a10', 'c12']}
              // onChange={handleChange}
              options={products.map(product=>{
                return {label:product.name,value:product.id}
              })}
            />
            </Form.Item>
            <Form.Item label="Images">
              <Upload
                customRequest={onFileUpload}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 5 ? null : uploadButton}
              </Upload>
            </Form.Item>
            {/* <Form.Item style={{textAlign:"center"}}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Create
              </Button>
            </Form.Item> */}
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmltype="submit">
                  {
                    loading? <LoadingSpinner/>: "Create"
                  }
                </button>
              </Form.Item>
            </div>
          </Form>
          <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default ProductForm;