import React, { useEffect, useState , useRef} from "react";
import { Form, Input, Button, Select, Upload,Modal } from "antd";
import { onSuccess } from "../../libs/onSuccess";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../config";
import BannerCrudService from "../../services/BannersCrud.service";
import { onError } from "../../libs/errorLib";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import moment from "moment";
const {Option} = Select

const EditBanner = ({ selectedBanner, ReLoad, handleCancel }) => {
  const [loading, setLoading] = useState(false)
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const [imageFileList, setImageFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [shouldSubmit, setShouldSubmit] = useState(true);


  // console.log("selected Banner", selectedBanner)
  useEffect(() => {
    form.setFieldsValue(selectedBanner);
    const { image } = selectedBanner;
  
    if (image) {
      const newFile = {
        uid: Date.now(),
        name: image,
        status: "done",
        path: image,
        url: config.IMAGE_BASE_URL + image,
      };
      
      setImageFileList([newFile]);
    } else {
      setImageFileList([]);
    }
  }, [selectedBanner]);


  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    if(imageFileList.length){
      setLoading(true)
      try {
        const formData = new FormData();
        // formData.append("name", values.name);
        formData.append("sortOrder", values.sortOrder);
        
        if (imageFileList && imageFileList.length && imageFileList[0].originFileObj) {
          // console.log("imageFileList[0]",imageFileList[0])
          formData.append("image", imageFileList[0].originFileObj);
        }

        await BannerCrudService.update(selectedBanner.id, formData);

        onSuccess("Banner updated successfully!");
        setLoading(false)
        setImageFileList([]);
        
        ReLoad()
      } catch (error) {
        console.error("Error updating product:", error);
        setLoading(false)
        // Handle error with an appropriate error message
      }
    } else{
      if(!imageFileList.length){
        onError("Image is Required")
      }
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // console.log("file List", imageFileList)
  };

  const handlePreviewCancel = () => setPreviewVisible(false);

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedBanner.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedBanner.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedBanner.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Form form={form} layout="vertical" name="editProductForm" onFinish={onFinish} initialValues={selectedBanner}>

        <div className="btns_group">
          <Form.Item label="Image" name="image">
            <Upload
              required
              listType="picture-card"
              fileList={imageFileList}
              onPreview={handlePreview}
              onChange={handleImageChange}
              beforeUpload={() => false} // Prevent default file upload
            >
              {imageFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </div>

        {/* <Form.Item label="Banner Text" name="name">
          <Input placeholder="Banner Text"/>
        </Form.Item> */}

        <Form.Item label="Sort Order" name="sortOrder"
          rules={[{ required: true, message: "Please select the sort order" }]}
        >
          <Input type="number" placeholder="Sort Order" min={0}/>
        </Form.Item>
                
        <div className="btns_group">
          <Form.Item>
            <button className="primary_btn" htmlType="submit">
              {loading ? <LoadingSpinner/>:"Update"}
            </button>
          </Form.Item>
          <button className="secondary_btn" onClick={handleCancelBtn}>
            Cancel
          </button>
        </div>
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditBanner;