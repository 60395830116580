import React, { useState } from "react";
import { Link } from "react-router-dom";

const SubMenu = ({ item,index }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <div className="sub-menu">
        
        <Link onClick={item.subNav && showSubnav} className="sub-menu-title">
        <h3><span>{item.icon}</span>{item.title}</h3>
          {item.subNav && subnav
          ? item.iconOpened
          : item.subNav
          ? item.iconClosed
          : null}
        </Link>
        {subnav &&
        item.subNav?.map((item, index) => {
          return (
            <div key={item.key} className="drop-down">
              <Link to={item.path} key={index}>
                <span>{item.icon}</span>
                {item.title}
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SubMenu;
