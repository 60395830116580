import { createSlice } from "@reduxjs/toolkit";

// =============== Create ENUMS mechanism ===============
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data:[]
};

const BannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setBanners(state, action) {
      // console.log(" Banners slice data",action)
      state.data=action.payload
    },
  },
});

export const { setBanners} = BannersSlice.actions;
export default BannersSlice.reducer;

// export const selectCurrentToken = (state) => state.auth.access_token;
