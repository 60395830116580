import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";

export const SiderData = [
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <AiIcons.AiFillDashboard />,
  // },
  {
    title: "Banners",
    path: "/banners",
    icon: <GiIcons.GiKnightBanner />,
  },
  {
    title: "Categories",
    path: "/categories",
    icon: <BiIcons.BiCategory />,
  },
  {
    title: "Sub Categories",
    path: "/sub-categories",
    icon: <BiIcons.BiCategoryAlt />,
  },
  {
    title: "Products",
    path: "/products",
    icon: <MdIcons.MdOutlineCategory />,
  },
  {
    title: "Blogs Categories",
    path: "/blogs-categories",
    icon: <FaIcons.FaBlog />,
  },
  {
    title: "Blogs",
    path: "/blogs",
    icon: <FaIcons.FaBlog />,
  },
  {
    title: "Instant Quote",
    path: "/instant-quotes",
    icon: <FaIcons.FaQuoteLeft />,
  },
  {
    title: "Custom Quote",
    path: "/custom-quotes",
    icon: <FaIcons.FaQuoteLeft />,
  },
  // {
  //   title: "Settings",
  //   path: "/settings",
  //   icon: <AiIcons.AiTwotoneSetting />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Departments",
  //       path: "/department/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Manage Job Groups",
  //       path: "/job-group/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Manage Contract Types",
  //       path: "/admin/contract-type/list",
  //       icon: <BsIcons.BsCircle />,
  //     },
  //     {
  //       title: "Manage Job Positions",
  //       path: "/admin/job-position/list",
  //       icon: <BsIcons.BsCircle />,
  //     },
  //   ],
  // },
  // {
  //   title: "Users & Permissions",
  //   path: "/permissions",
  //   icon: <FaIcons.FaUsers />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Users",
  //       path: "/admin/user/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Audit Trail",
  //       path: "/admin/audit-trail/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "Job Vacancies",
  //   path: "/jobs",
  //   icon: <BsIcons.BsHandbagFill />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Job Openings",
  //       path: "/admin/job-opening/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Manage Applications",
  //       path: "/admin/job-applications/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "Profiles",
  //   path: "/profiles",
  //   icon: <FaIcons.FaEnvelopeOpenText />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Profiles",
  //       path: "/admin/job-profiles/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     }
  //   ],
  // },
  // {
  //   title: "Notices & Shortlists",
  //   path: "/shortlists",
  //   icon: <BsIcons.BsFillFlagFill />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Notices",
  //       path: "/admin/notices/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Manage Shortlists",
  //       path: "/admin/shortlists/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     }
  //   ],
  // },
  // {
  //   title: "News",
  //   path: "/news",
  //   icon: <BsIcons.BsFillFlagFill />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Uploads",
  //       path: "/admin/news/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     }
  //   ],
  // },
  // {
  //   title: "Helpdesk & Support",
  //   path: "/helpdesk",
  //   icon: <IoIcons.IoMdHelpCircle />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage HelpDesk",
  //       path: "/admin/support-ticket/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     }
  //   ],
  // },
  // {
  //   title: "Bulk Sms",
  //   path: "/contact-group",
  //   icon: <MdIcons.MdOutlineTextsms />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Manage Contact List",
  //       path: "/admin/contact-group/list",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Bulksms from Contact-List ",
  //       path: "/admin/send-sms/add-bulksms",
  //       icon: <BsIcons.BsCircle />,
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Bulksms from file ",
  //       path: "/admin/send-sms/add-bulksms-file",
  //       icon: <BsIcons.BsCircle />,
  //     },
  //     {
  //       title: "Send Sms",
  //       path: "/admin/send-sms/add-sms",
  //       icon: <BsIcons.BsCircle />,
  //     },
  //     {
  //       title: "Bulksms Report",
  //       path: "/admin/send-sms/add-sms-search",
  //       icon: <BsIcons.BsCircle />,
  //     },
  //   ],
  // },
  // {
  //   title: "Reports",
  //   path: "/reports",
  //   icon: <GoIcons.GoReport />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },

];
