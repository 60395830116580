import React, { useEffect, useState , useRef} from "react";
import { Form, Input, Button, Select, Upload,Modal } from "antd";
import { onSuccess } from "../../libs/onSuccess";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../config";
import CategoriesCrudService from "../../services/SubCategoriesCrud.service";
import { useSelector } from "react-redux";
import { onError } from "../../libs/errorLib";
import ReactQuill from "react-quill";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import moment from "moment";
const {Option} = Select

const EditSubCategories = ({ selectedSubCategory, ReLoad, handleCancel,subcategories,related }) => {
  const [loading, setLoading] = useState(false)
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const categoriesList = useSelector((state)=> state.categories.data )
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [imageFileList, setImageFileList] = useState([]);
  const [thumbFileList, setThumbFileList] = useState([]);
  const [bannerFileList, setBannerFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [relatedC,setRelated]=useState(related)
  const [shouldSubmit, setShouldSubmit] = useState(true);
  // console.log("selected Category", selectedSubCategory)
  useEffect(()=>{
    if(related){
      setRelated(related)
    }else{
      setRelated([])
    }

  },[related])
  useEffect(() => {
    form.setFieldsValue(selectedSubCategory);
    form.setFieldsValue({related});

    setAdditionalDescription(selectedSubCategory.additionalDescription)
    const { image, thumbnail, banner } = selectedSubCategory;
  
    if (image) {
      const newFile = {
        uid: Date.now(),
        name: image,
        status: "done",
        path: image,
        url: config.IMAGE_BASE_URL + image,
      };
      
      setImageFileList([newFile]);
    } else {
      setImageFileList([]);
    }
    // console.log("sub Category Image", image)
    if (thumbnail) {
      // console.log("thumbnaill")
      const newFile = {
        uid: Date.now(),
        name: image,
        status: "done",
        path: image,
        url: config.IMAGE_BASE_URL + thumbnail,
      };
      
      setThumbFileList([newFile]);
    } else {
      // console.log("thumbnaill empty")
      setThumbFileList([]);
    }
    if (banner) {
      // console.log("thumbnaill")
      const newFile = {
        uid: Date.now(),
        name: image,
        status: "done",
        path: image,
        url: config.IMAGE_BASE_URL + banner,
      };
      
      setBannerFileList([newFile]);
    } else {
      // console.log("thumbnaill empty")
      setBannerFileList([]);
    }
  }, [selectedSubCategory]);

  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    if(imageFileList.length && thumbFileList.length && bannerFileList.length){
      setLoading(true)
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("permalink", values.permalink);
        // formData.append("bannerText", values.bannerText);
        formData.append("schemaHeader", values.schemaHeader);
        formData.append("additionalDescription", additionalDescription);
        formData.append("seoTitle", values.seoTitle);
        formData.append("seoDescription", values.seoDescription);
        formData.append("seoKeywords", values.seoKeywords);
        formData.append("categoryId", values.categoryId);
        if(values.related){
          values.related.forEach((value, index) => {
            formData.append(`related[${index}]`, value);
          });
        }else{
          formData.append(`related[]`, null);

        }
     
        // Handle image upload
        if (imageFileList && imageFileList.length && imageFileList[0].originFileObj) {
          // console.log("imageFileList[0]",imageFileList[0])
          formData.append("image", imageFileList[0].originFileObj);
        }
        
        if (thumbFileList && thumbFileList.length && thumbFileList[0].originFileObj) {
          // console.log("thumbFileList[0]",thumbFileList[0])
          formData.append("thumbnail", thumbFileList[0].originFileObj);
        }
        if (bannerFileList && bannerFileList.length && bannerFileList[0].originFileObj) {
          // console.log("bannerFileList[0]",bannerFileList[0])
          formData.append("banner", bannerFileList[0].originFileObj);
        }

        await CategoriesCrudService.update(selectedSubCategory._id, formData);

        onSuccess("Sub Category updated successfully!");
        setLoading(false)
        setImageFileList([]);
        setThumbFileList([]);
        setBannerFileList([]);
        if (reactQuillRef.current) {
          reactQuillRef.current.getEditor().setContents([]);
        }
        ReLoad()
      } catch (error) {
        console.error("Error updating product:", error);
        setLoading(false)
        // Handle error with an appropriate error message
      }
    } else{
      if(!imageFileList.length){
        onError("Image is Required")
      } else if(!thumbFileList.length){
        onError("Thumbnail is Required")
      } else if(!bannerFileList.length){
        onError("Banner is Required")
      }
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // console.log("file List", imageFileList)
  };

  const handlePreviewCancel = () => setPreviewVisible(false);

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };

  const handleThumbChange = ({ fileList }) => {
    setThumbFileList(fileList);
  };
  const handleBannerChange = ({ fileList }) => {
    setBannerFileList(fileList);
  };

  // console.log("selected Category", selectedSubCategory)
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedSubCategory.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedSubCategory.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedSubCategory.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Form form={form} layout="vertical" name="editProductForm" onFinish={onFinish} initialValues={selectedSubCategory}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter the category name" }]}>
          <Input placeholder="Category Name"/>
        </Form.Item>
        <Form.Item label="Description" name="description"
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <Input placeholder="Description"/>
        </Form.Item>
        <ReactQuill
          value={additionalDescription}
          placeholder="write additional description here..."
          onChange={(value) => {
            setAdditionalDescription(value);
          }}
        />
        {/* <Form.Item label="Category ID" name="categoryId">
          <Input />
        </Form.Item> */}        
        
        <Form.Item
          name="categoryId"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select a category">
            {categoriesList?.map((category) => (
              <Option key={category._id} value={category._id} 
              selected= {category._id == selectedSubCategory.categoryId}
              >
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item name="bannerText" label="Banner Text">
          <Input placeholder="Banner Text" />
        </Form.Item> */}

        <Form.Item name="schemaHeader" label="Schema Header"
          rules={[{ required: false, message: "Please enter the schema header" }]}
        >
          <Input placeholder="Schema Header" />
        </Form.Item>

        <Form.Item name="permalink" label="Perma Link"
          rules={[{ required: true, message: "Please enter the perma link" }]}
        >
          <Input placeholder="Perma Link" />
        </Form.Item>
        
        <Form.Item label="SEO Title" name="seoTitle">
          <Input />
        </Form.Item>
        
        <Form.Item label="SEO Description" name="seoDescription">
          <Input.TextArea />
        </Form.Item>
        
        <Form.Item label="SEO Keywords" name="seoKeywords">
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <input type="file" accept="image/*" multiple />
        </Form.Item> */}
          <Form.Item name="related" label="Related Categories">
            <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      initialValues={relatedC}

      // onChange={handleChange}
      options={subcategories.map(category=>{
        return {label:category.name,value:category._id}
      })}
    />
            </Form.Item>
        <div className="btns_group">
          <Form.Item label="Image" name="image">
            <Upload
              required
              listType="picture-card"
              fileList={imageFileList}
              onPreview={handlePreview}
              onChange={handleImageChange}
              beforeUpload={() => false} // Prevent default file upload
            >
              {imageFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="Thumbnail" name="thumbnail">
            <Upload
              listType="picture-card"
              fileList={thumbFileList}
              onPreview={handlePreview}
              onChange={handleThumbChange}
              beforeUpload={() => false} // Prevent default file upload
            >
              {thumbFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="Banner" name="banner">
            <Upload
              listType="picture-card"
              fileList={bannerFileList}
              onPreview={handlePreview}
              onChange={handleBannerChange}
              beforeUpload={() => false} // Prevent default file upload
            >
              {bannerFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </div>
        {/* <Form.Item
          name="thumbnail"
          label="Thumbnail"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <input type="file" accept="image/*" multiple />
        </Form.Item> */}
        <div className="btns_group">
          <Form.Item>
            <button className="primary_btn" htmlType="submit">
              {loading ? <LoadingSpinner/>:"Update"}
            </button>
          </Form.Item>
          <button className="secondary_btn" onClick={handleCancelBtn}>
            Cancel
          </button>
        </div>
        {/* <Form.Item style={{textAlign:"center"}}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item> */}
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditSubCategories;