import axios from "axios";
import config from "../config";
import {getCookie, deleteCookie, setCookie} from "./cookie";
import {removeLoginUser} from "../Store/Auth/authSlice";
import store from "../Store/store"

const baseInstance = axios.create({
    baseURL: config.API_BASE_URL,
});
baseInstance.defaults.headers.common["Accept"] = "*/*";
baseInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
baseInstance.interceptors.request.use(function (config) {
    const token = getCookie("access_token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = `Basic Y3BzYl91aTplM2EzY2Q2OTc3MDQ3OTcwM2RkY2Y0MGJiNzNiNjBhOWQ0OWE5ODE4NTE3N2FkOTE5MzlmZmE2ZmU1Zg==`;
    }
    return config;
});
baseInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const { config } = error;
        if (error.response?.status === 401 && !config._retry) {
          config._retry = true;
          return axios
            .post(
              `auth/refresh-tokens`,
              {
                refreshToken: getCookie("refresh_token"),
                // grant_type: "refresh_token",
              },
              {
                headers: {
                  // "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `Basic Y3BzYl91aTplM2EzY2Q2OTc3MDQ3OTcwM2RkY2Y0MGJiNzNiNjBhOWQ0OWE5ODE4NTE3N2FkOTE5MzlmZmE2ZmU1Zg==`,
                },
              }
            )
            .then((res) => {
              const { access_token, refresh_token } = res.data;
              // console.log("response tokens", res)
              setCookie("refresh_token", refresh_token);
              setCookie("access_token", access_token);
              return baseInstance(config);
            })
            .catch((err) => {
              // console.log("response tokens catch", err)
              // console.log("config._retry", err.response.status);

              if (err.response.status === 401 || err.response.status === 500) {
                deleteCookie("access_token");
                deleteCookie("refresh_token");
                store.dispatch(removeLoginUser());
                window.location.href = "/";
                // console.log("catch", err);
              }

              return Promise.reject(err);
            });
        }
        // console.log('awais',error)

        return Promise.reject(error);
    }
);
export default baseInstance;
