import axios from '../libs/axios';

class SubCategoriesCrudService {
    constructor() {
        this.basePath=`/subcategory`
    }

    all() {
        return axios.get(`${this.basePath}`,{
        });
    }
    view(id){
        return axios.get(`${this.basePath}/${id}`, {});
    }
    hot(id, payload){
        return axios.patch(`${this.basePath}/markHot/${id}`, payload);
    }
    add(payload){
        return axios.post(`${this.basePath}`, payload,{
        });
    }
    update(id,payload) {
        return axios.patch(`${this.basePath}/${id}`, payload,{
        });
    }
    delete(id) {
        return axios.delete(`${this.basePath}/${id}`);
    }
    uploadImages(payload) {
        return axios.delete(`${this.basePath}/subcategory/upload`,payload);
    }
    getOne(id){
        return axios.get(`${this.basePath}/${id}`);
    }
}

export default new SubCategoriesCrudService();
