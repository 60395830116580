import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch} from "react-redux";
import {setLoginUser} from "../../../Store/Auth/authSlice";
import {deleteCookie, setCookie} from "../../../libs/cookie";
import {onError} from "../../../libs/errorLib";
import { onSuccess } from "../../../libs/onSuccess";
import AuthService from '../../../services/auth.service';
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";

const Login = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const onFinish = async (values) => {
        try {
            deleteCookie("access_token")
            deleteCookie("refresh_token")
            const {email, password} = values
            setIsLoading(true);
            let data = {
                // grant_type: "password",
                email,
                password
            };
            const result = await AuthService.login(data)
            // console.log("login res",result)
            dispatch(setLoginUser(result));
            // setCookie("access_token", result.token.access)
            // setCookie("refresh_token", result.token.refresh)
            onSuccess("sucessfully Logged in")
            // Set the cookie expiration date
            const accessExpires = new Date(result.token.access.expires);
            const refreshExpires = new Date(result.token.refresh.expires);
            const accessToken = `access_token=${result.token.access.token}; expires=${accessExpires.toUTCString()}; path=/`;
            document.cookie = accessToken;
            const refreshToken = `refresh_token=${result.token.refresh.token}; expires=${refreshExpires.toUTCString()}; path=/`;
            document.cookie = refreshToken;
            

        } catch (error) {
            setIsLoading(false);
            onError("Login Failed")
            // console.log("Failed catch1:", error);
        }
    }
    const onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    };
    return (
        <Form
            name="basic"
            labelCol={{
                offset:4,
                span: 4,
            }}
            wrapperCol={{
                span: 12,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please input correct email",
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <button className="primary_btn" 
                // htmlType="submit"
                >
                    {isLoading?
                        <LoadingSpinner/>:"Log in"
                    }
                </button>
            </Form.Item>
        </Form>
    );
};

export default Login;
