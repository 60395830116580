import { createSlice } from "@reduxjs/toolkit";

// =============== Create ENUMS mechanism ===============
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data:[]
};

const BlogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogs(state, action) {
      // console.log(" Banners slice data",action)
      state.data=action.payload
    },
  },
});

export const { setBlogs} = BlogsSlice.actions;
export default BlogsSlice.reducer;

// export const selectCurrentToken = (state) => state.auth.access_token;
