import React from "react";
import routes from "../../Routes/Routes";
import RenderRoute from "../../Routes/RenderRoute";
import {BrowserRouter as Router, Route} from "react-router-dom"
import MainLayout from "../../layouts/MainLayout";
import AuthLayout from "../../layouts/AuthLayout";
import Login from "../Auth/index";
import UnAuthenticatedRoute from "../../Routes/UnAuthenticatedRoute";
import {useSelector} from "react-redux";

const AdminLayout = () => {
    const {isLoggedIn} = useSelector(({auth}) => auth)
    return (
      <Router>
        {isLoggedIn ? (
          <MainLayout>
            {routes?.map((route) => (
              <RenderRoute
                key={route.path}
                Component={route.component}
                path={route.path}
                to={route.to}
                CustomRoute={route.CustomRoute}
              />
            ))}
          </MainLayout>
        ) : (
          <RenderRoute
            key="/"
            Layout={AuthLayout}
            Component={Login}
            path="/"
            CustomRoute={UnAuthenticatedRoute}
          />
        )}
      </Router>
    );

};

export default AdminLayout;
