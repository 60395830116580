import { createSlice } from "@reduxjs/toolkit";

// =============== Create ENUMS mechanism ===============
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data:[]
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      // console.log(" products slice data",action)
      state.data=action.payload
    },
    createProduct(state, action) {
      // console.log(" product create slice data",action)
      state.data=[...state.products.data, action.payload]
    },
    // removeProducts(state, action) {
    //   state.products=[]
    // },
  },
});

export const { setProducts, createProduct, removeProducts } = productsSlice.actions;
export default productsSlice.reducer;

// export const selectCurrentToken = (state) => state.auth.access_token;
