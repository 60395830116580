import axios from '../libs/axios';

class NoticesService {
    constructor() {
        this.basePath=`/product`
    }

    all() {
        // const contentType='text/plain'
        return axios.get(`${this.basePath}`,{
            // headers:{
            //     'Content-Type':contentType
            // }
        });
    }
    view(id){
        return axios.get(`${this.basePath}/${id}`, {});
    }
    add(payload){
        // const contentType='application/notice.create.v1+json'
        return axios.post(`${this.basePath}`, payload,{
            // headers:{
            //     'Content-Type':contentType
            // }
        });
    }
    update(id,payload) {
        // const contentType = 'application/notice.v1+json'
        return axios.patch(`${this.basePath}/${id}`, payload,{
            // headers:{
            //     'Content-Type':contentType
            // }
        });
    }
    delete(id) {
        return axios.delete(`${this.basePath}/${id}`);
    }
    upload(payload) {
        // console.log('payload',payload)
        return axios.post(`/subcategory/upload`,payload,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        });
    }
    getOne(id){
        return axios.get(`${this.basePath}/${id}`);
    }
}

export default new NoticesService();
