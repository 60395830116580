import { createSlice } from "@reduxjs/toolkit";

// =============== Create ENUMS mechanism ===============
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  isLoggedIn: false,
//   id: null,
//   name: null,
//   email: null,
  // token_type: null,
  // access_token: null,
  // refresh_token: null,
  status: STATUSES.IDLE,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginUser(state, action) {
      // console.log("slice data",action)
      const { user, token } = action.payload;
      state.isLoggedIn = true;
      state.tokens = token;
      state.user = user;
    },
    removeLoginUser(state, action) {
      state.isLoggedIn = false;
      state.tokens = null;
      state.user = null;
    },
  },
});

export const { setLoginUser, removeLoginUser } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.access_token;
