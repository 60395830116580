
import {message} from 'antd';
import { toast } from 'react-toastify';

export function onError(error) {
    if (error.response?.message) {
        toast.error(error.response?.message);
    } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
    } else {
        toast.error(error);
    }
}
