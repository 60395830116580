import React, { useEffect, useState , useRef} from "react";
import config from "../../config";
import moment from "moment";

const ViewQuotations = ({ selectedQuote, ReLoad, handleCancel, download }) => {

  console.log("selectedQuote", selectedQuote)

  return (
    <>
      
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedQuote.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <div className="record_row">
        <div className="record_item">
          <h4>Customer Name:</h4>
          <p>{selectedQuote.name}</p>
        </div>
        <div className="record_item">
          <h4>Phone:</h4>
          <p>{selectedQuote.mobile}</p>
        </div>
      </div>

      <div className="record_row">
        <div className="record_item">
          <h4>Email:</h4>
          <p>{selectedQuote.email}</p>
        </div>
        <div className="record_item">
          <h4>Company:</h4>
          <p>{selectedQuote.company_name ? selectedQuote.company_name : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row">
        <div className="record_item">
          <h4>Product Name:</h4>
          <p>{selectedQuote.product_name ? selectedQuote.product_name : 'N/A' }</p>
        </div>
        <div className="record_item">
          <h4>Quantity:</h4>
          <p>{selectedQuote.quantity ? selectedQuote.quantity : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row"> 
        <div className="record_item">
          <h4>Thickness:</h4>
          <p>{selectedQuote.thickness ? selectedQuote.thickness : 'N/A' }</p>
        </div> 
        <div className="record_item">
          <h4>Color:</h4>
          <p>{selectedQuote.color ? selectedQuote.color : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row"> 
        <div className="record_item">
          <h4>Printing Sides:</h4>
          <p>{selectedQuote.printing_sides ? selectedQuote.printing_sides : 'N/A' }</p>
        </div> 
        <div className="record_item">
          <h4>Finishing:</h4>
          <p>{selectedQuote.finishing ? selectedQuote.finishing : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row">
        <div className="record_item">
          <h4>Coating:</h4>
          <p>{selectedQuote.coating ? selectedQuote.coating : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row">
        <div className="record_item">
          <h4>Length:</h4>
          <p>{selectedQuote.length ? selectedQuote.length : 'N/A' }</p>
        </div>
        <div className="record_item">
          <h4>Width:</h4>
          <p>{selectedQuote.width ? selectedQuote.width : 'N/A' }</p>
        </div>
      </div>
      <div className="record_row">
        <div className="record_item">
          <h4>Dimension:</h4>
          <p>{selectedQuote.dimension ? selectedQuote.dimension : 'N/A' }</p>
        </div>
        <div className="record_item">
          <h4>Scale:</h4>
          <p>{selectedQuote.scale ? selectedQuote.scale : 'N/A' }</p>
        </div>
      </div>

      <div className="record_row">
        <div className="record_item full_width">
          <h4>Message:</h4>
          <p>{selectedQuote.message ? selectedQuote.message : 'N/A' }</p>
        </div>
      </div>
      
      <div className="record_row">
        <div className="record_item">
          <h4>Attachment:</h4>
          {
            (selectedQuote.file && selectedQuote.file!=='null') ? 
              <button className="primary_btn" onClick={() => download(selectedQuote.file)}>Click to Download</button>
            : 'N/A'
          }
        </div>
      </div>

      <div className="btns_group">
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
      
    </>
  );
};

export default ViewQuotations;