import React, { useState, useRef, useEffect} from "react";
import { Row, Col, Form, Input, Button, Modal, Select, Upload } from "antd";
import CategoriesCrudService from "../../services/SubCategoriesCrud.service";
import { PlusOutlined } from "@ant-design/icons";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import {useHistory} from "react-router-dom";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import LoadingSpinner from './../../Components/LoadingSpinner/LoadingSpinner';
const { Option } = Select; // Destructure Option from Select

const CreateSubCategories = ({ReLoad,subcategories}) => {
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const categoriesList = useSelector((state)=> state.categories.data )

  
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [imageFileList, setImageFileList] = useState([]);
  const [thumbFileList, setThumbFileList] = useState([]);
  const [bannerFileList, setBannerFileList] = useState([]);

  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleImageChange = ({ fileList }) => {
    // console.log("category file list ",fileList)
    setImageFileList(fileList);
  };
  // const handleThumbChange = ({ fileList }) => {
  //   // console.log("category file list ",fileList)
  //   setThumbFileList(fileList);
  // };
  const handleBannerChange = ({ fileList }) => {
    // console.log("category file list ",fileList)
    setBannerFileList(fileList);
  };


  const onFinish = async (values) => {
    if(imageFileList.length && bannerFileList.length){
      setLoading(true);
      try {
        console.log('values',values)
        // Prepare form data
        // console.log("imageFileList [0]",imageFileList[0].originFileObj)
        const formData = new FormData();
        formData.append("image", imageFileList[0].originFileObj)
        // formData.append("thumbnail", thumbFileList[0].originFileObj)
        formData.append("banner", bannerFileList[0].originFileObj)
        // formData.append("image", fileList[0]);
        formData.append("name", values.name);
        // formData.append("bannerText", values.bannerText);
        formData.append("permalink", values.permalink);
        formData.append("schemaHeader", values.schemaHeader);
        formData.append("description", values.description);
        formData.append("additionalDescription", additionalDescription);
        formData.append("seoTitle", values.seoTitle);
        formData.append("seoDescription", values.seoDescription);
        formData.append("seoKeywords", values.seoKeywords);
        formData.append("categoryId", values.categoryId);
        // formData.append("related[]", values.related);
        if(values.related){
          values?.related.forEach((value, index) => {
            formData.append(`related[${index}]`, value);
          });
        }
      
        console.log('awais')
        // const datt = {...formData, "image": fileList[0]}
        await CategoriesCrudService.add(formData)
        onSuccess("Sub Category created Successfully.")
        form.resetFields();
        setImageFileList([]);
        // setThumbFileList([]);
        setBannerFileList([]);
        if (reactQuillRef.current) {
          reactQuillRef.current.getEditor().setContents([]);
        }
        ReLoad()
      } catch (e) {
          onError(e)
      } finally {
          setLoading(false)
      }
    } else {
      // console.log("elseeee")
      if(!imageFileList.length){
        onError("Image is Required")
      } else if(!bannerFileList.length){
        // console.log("elsee banner")
        onError("Banner is Required")
      }
    }
  };

  return (
    <>
      
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical" size="small">
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter the category name" }]}
            >
              <Input placeholder="Category Name" />
            </Form.Item>
            <Form.Item name="description" label="Description"
              rules={[{ required: true, message: "Please enter the description" }]}
            >
              <Input placeholder="Description" />
            </Form.Item>
            <ReactQuill
              ref={reactQuillRef}
              placeholder="write additional description here..."
              onChange={(value, delta, source, editor) => {
                setAdditionalDescription(editor.getHTML);
              }}
            />
            <Form.Item
              name="categoryId"
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Select a category">
                {categoriesList?.map((category) => (
                  <Option key={category._id} value={category._id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item name="bannerText" label="Banner Text">
              <Input placeholder="Banner Text" />
            </Form.Item> */}
            <Form.Item name="schemaHeader" label="Schema Header Text"
              rules={[{ required: false, message: "Please enter the schema header" }]}
            >
              <Input placeholder="Schema Header Text" />
            </Form.Item>
            <Form.Item name="permalink" label="Perma Link"
              rules={[{ required: true, message: "Please enter the perma link" }]}
            >
              <Input placeholder="Perma Link" />
            </Form.Item>
            <Form.Item name="seoTitle" label="SEO Title">
              <Input placeholder="SEO Title" />
            </Form.Item>
            <Form.Item name="seoDescription" label="SEO Description">
              <Input.TextArea placeholder="SEO Description" />
            </Form.Item>
            <Form.Item name="seoKeywords" label="SEO Keywords">
              <Input placeholder="SEO Keywords" />
            </Form.Item>
            <Form.Item name="related" label="Related Categories">
            <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      options={subcategories.map(category=>{
        return {label:category.name,value:category._id}
      })}
    />
            </Form.Item>
            {/* <Form.Item
              name="image"
              label="Product Image"
              rules={[{ required: true, message: "Please select an image" }]}
            >
              <input type="file" accept="image/*" />
            </Form.Item> */}
            <div className="btns_group">
              <Form.Item label="Image">
                <Upload
                  listType="picture-card"
                  fileList={imageFileList}
                  onPreview={handlePreview}
                  onChange={handleImageChange}
                  beforeUpload={() => false} // Prevent default file upload
                >
                  {imageFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
              {/* <Form.Item label="Thumbnail">
                <Upload
                  listType="picture-card"
                  fileList={thumbFileList}
                  onPreview={handlePreview}
                  onChange={handleThumbChange}
                  beforeUpload={() => false} // Prevent default file upload
                >
                  {thumbFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item> */}
              <Form.Item label="Banner">
                <Upload
                  listType="picture-card"
                  fileList={bannerFileList}
                  onPreview={handlePreview}
                  onChange={handleBannerChange}
                  beforeUpload={() => false} // Prevent default file upload
                >
                  {bannerFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </div>
            <Form.Item style={{textAlign:"center"}}>
              <Button type="primary" htmlType="submit" loading={loading}>
              {
                loading? <LoadingSpinner/>:"Create"
              }
              </Button>
            </Form.Item>
          </Form>

          <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default CreateSubCategories;
