import React, { useEffect, useState, useRef } from "react";
import { Table, Input, Button, Space, Modal,Row, Col } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { onSuccess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import EditBanner from './EditBanner';
import DeleteBanner from './DeleteBanner';
import CreateBanner from "./CreateBanner";
import BannerCrudService from "../../services/BannersCrud.service";
import { useDispatch } from 'react-redux';
import { setBanners } from "../../Store/Banners/BannersSlice";
import moment from "moment";


const Banners = () => {
  const dispatch = useDispatch();
  const [allBanners, setAllBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // Modals
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  
  useEffect(() => {
    getallBannersList();
  }, []);
  
  const getallBannersList = async () => {
    setLoading(true);
    try {
      const response = await BannerCrudService.all();
      // console.log("allBanners", response);
      setAllBanners(response.data);
      dispatch(setBanners(response.data))
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => (clearFilters && handleReset(clearFilters), handleSearch(selectedKeys, confirm, dataIndex))}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit = (record) => {
    setSelectedBanner(record);
    setEditModalVisible(true);
  };

  const handleDelete = (record) => {
    setSelectedBanner(record);
    setDeleteModalVisible(true);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={config.IMAGE_BASE_URL + image} alt={image} style={{ maxWidth: "100px" }} />
      ),
    },
    // {
    //   title: "Banner Text",
    //   dataIndex: "name",
    //   key: "name",
    //   ...getColumnSearchProps("name"),
    //   sorter: (a, b) => a.name.localeCompare(b.name), // Add sorter for the "Name" column
    //   sortDirections: ["ascend", "descend"],
    // },
    {
      title: "Sort Order",
      dataIndex: "sortOrder",
      key: "sortOrder",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnSearchProps("createdAt"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt), // Add sorter for the "createdAt" column
      sortDirections: ["ascend", "descend"],
      render: (createdAt) => moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => moment(updatedAt).format("YYYY MMM, DD - hh:mm:ss a"), 
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <EditOutlined title="Edit" onClick={() => handleEdit(record)}/>
          <DeleteOutlined title="Delete" onClick={() => handleDelete(record)}/>
        </Space>
      ),
    },
  ];


// create product modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => { setIsModalOpen(false);
  };
  return (
    <>
      <Row  className="page_title">
        <h2>Banners</h2>
        <div className="btns_group">
          <Button className="primary_btn" onClick={getallBannersList} loading= {loading} >Refresh</Button>
          <button className="primary_btn" onClick={showModal}>create Banner</button>
        </div>
        <Modal title="Create Banner" 
          open={isModalOpen} 
          // onOk={handleOk} 
          onCancel={handleCancel} 
          footer="">
          {isModalOpen && 
            <CreateBanner
              ReLoad={() => {
                setIsModalOpen(false);
                getallBannersList(); // Refresh the product list after editing
              }}
              
            />
          }
        </Modal>
      </Row>
      <Table
        dataSource={allBanners}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Edit Banner"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        {/* Edit form */}
        {selectedBanner && (
          <EditBanner
            selectedBanner={selectedBanner}
            ReLoad={() => {
              setEditModalVisible(false);
              getallBannersList(); // Refresh the product list after editing
            }}
            handleCancel = {() => setEditModalVisible(false)}
          />
        )}
      </Modal>
      <Modal
        title="Delete Banner"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={null}
      >
        {/* Delete confirmation */}
        {selectedBanner && (
          <DeleteBanner
            selectedBanner={selectedBanner}
            ReLoad={() => {
              setDeleteModalVisible(false);
              getallBannersList(); // Refresh the product list after deleting
            }}
            handleCancel = {() => setDeleteModalVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default Banners;