import React,{useState, useRef } from "react";
import { Form, Input, Button, InputNumber, Upload, Modal, message, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { onSuccess } from "../../libs/onSuccess";
import ProductCrudService from "../../services/ProductCrud.service";
import { useEffect } from "react";
import config from "../../config";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from "moment";
import ReactQuill from "react-quill";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
const { Option } = Select; // Destructure Option from Select


const EditProduct = ({ selectedProduct, ReLoad, handleCancel,related,products}) => {
  const [loading, setLoading]= useState(false)
  const reactQuillRef = useRef(null);
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [additionalDescription, setAdditionalDescription] = useState("");
  const subCategoriesList = useSelector(({ subCategories }) => subCategories.data);
  const [relatedC,setRelated]=useState(related)

  const [shouldSubmit, setShouldSubmit] = useState(true);

  // console.log("selected edit",selectedProduct)
  useEffect(()=>{
    console.log(related)
    if(related){
      setRelated(related)
    }else{
      setRelated([])
    }

  },[related])
  useEffect(()=>{
    console.log("subCategoriesList", subCategoriesList)
    form.setFieldsValue(selectedProduct);
    form.setFieldsValue({related});
    setAdditionalDescription(selectedProduct.additionalDescription)
    const {images}=selectedProduct;
    const imagesArray=[]
    images?.map(image=>{
      const newFile = {
        uid: Date.now()+image, // Generate a unique UID
        name: image,
        status: 'done',
        path:image,
        // url: 'https://example.com/' + file.name, // Replace with the actual file URL
        url: config.IMAGE_BASE_URL+image, // Replace with the actual file URL
      };
      imagesArray.push(newFile)
    })
    setFileList(imagesArray)
  },[selectedProduct])

  const handleCancelBtn = () => {
    setShouldSubmit(false)
    handleCancel();
  }
  const onFinish = async (values) => {
    if(!shouldSubmit){
      handleCancelBtn();
      return;
    }
    setLoading(true)
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("images[]",file.path);
  
        });
      formData.append("name", values.name);
      formData.append("permalink", values.permalink);
      formData.append("schemaHeader", values.schemaHeader);
      formData.append("additionalDescription", additionalDescription);
      formData.append("description", values.description);
      // formData.append("description", description);
      formData.append("seoTitle", values.seoTitle);
      formData.append("seoDescription", values.seoDescription);
      formData.append("seoKeywords", values.seoKeywords);
      formData.append("categoryId", values.categoryId);

      // Add logic for handling image uploads if needed
      if(values.related){
        values.related.forEach((value, index) => {
          formData.append(`related[${index}]`, value);
        });
      }else{
        formData.append(`related[]`, null);

      }
      await ProductCrudService.update(selectedProduct.id,formData);

      onSuccess("Product updated successfully!");
      if (reactQuillRef.current) {
        reactQuillRef.current.getEditor().setContents([]);
      }
      setLoading(false)
      ReLoad()
    } catch (error) {
      console.error("Error updating product:", error);
      setLoading(false)
      // Handle error with an appropriate error message
    }
    
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    
    // console.log("fileList path", fileList)
  };
  const handlePreviewCancel = () => setPreviewVisible(false);
  const handleFileChange = (newFileList) => {
    // Update the file list when a file is successfully uploaded
    setFileList(newFileList);
  };
  const onFileUpload = async (options) => {
    // Simulate an async upload process (e.g., using Axios)
    // Replace this with your actual upload logic

    // Simulate a delay for demonstration purposes
    const { file, onProgress, onSuccess, onError } = options;
 
    try{
      const response=await ProductCrudService.upload({image:file});
      // console.log(response)
         // Create a new file object with a unique UID and URL
      onProgress({ percent: 100 });
      onSuccess()
         const newFile = {
          uid: Date.now(), // Generate a unique UID
          name: file.name,
          status: 'done',
          path:response.filename,
          url: config.IMAGE_BASE_URL+response.filename, // Replace with the actual file URL
        };
      handleFileChange([...fileList, newFile]);

    }catch(e){
      onError(e)
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <div className="creation_main">
        <div className="create_item">
          <h3>created at:</h3><p>{moment(selectedProduct.createdAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
        <div className="create_item">
          <h3>created by:</h3><p>{selectedProduct.createdBy}</p>
        </div>
        <div className="create_item">
          <h3>updated at:</h3><p>{moment(selectedProduct.updatedAt).format("YYYY MMM, DD - hh:mm:ss a")}</p>
        </div>
      </div>
      <Form form={form} name="editProductForm" layout="vertical" onFinish={onFinish} initialValues={selectedProduct}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter the product name" }]}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Description"
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <Input placeholder="Description" />
        </Form.Item>

        {/* <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item> */}
        <ReactQuill
          value={additionalDescription}
          placeholder="write additional description here..."
          onChange={(value) => {
            setAdditionalDescription(value);
          }}
        />
        
        <Form.Item
          name="categoryId"
          label="Category"
          rules={[{ required: true, message: "Please select a subcategory" }]}
        >
          <Select placeholder="Select a subcategory">
            {subCategoriesList?.map((subcategory) => (
              <Option key={subcategory._id} value={subcategory._id}>
                {subcategory.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="schemaHeader" label="Schema Header"
          rules={[{ required: false, message: "Please enter the schema header" }]}
        >
          <Input placeholder="Schema Header" />
        </Form.Item>
        <Form.Item name="permalink" label="Perma Link"
          rules={[{ required: true, message: "Please enter the perma link" }]}
        >
          <Input placeholder="Perma Link" />
        </Form.Item>
        <Form.Item label="SEO Title" name="seoTitle">
          <Input />
        </Form.Item>
        <Form.Item label="SEO Description" name="seoDescription">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="SEO Keywords" name="seoKeywords">
          <Input />
        </Form.Item>
        <Form.Item name="related" label="Related Products">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            initialValues={relatedC}

            // onChange={handleChange}
            options={products.map(product=>{
              return {label:product.name,value:product.id}
            })}
          />
            </Form.Item>

        <Form.Item label="Images">
          <Upload
            customRequest={onFileUpload}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 5 ? null : uploadButton}
          </Upload>
        </Form.Item>
        {/* <Form.Item style={{textAlign:"center"}}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
        <Form.Item style={{textAlign:"center"}}>
          <Button type="secondary">
            cancel
          </Button>
        </Form.Item> */}
        <div className="btns_group">
          <Form.Item>
            <button className="primary_btn" htmlType="submit">
              {loading? <LoadingSpinner/>:"Update"}
            </button>
          </Form.Item>
          <button className="secondary_btn" onClick={handleCancelBtn}>
            Cancel
          </button>
        </div>
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default EditProduct;
