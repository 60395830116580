import React,{useState} from "react";
import BlogsCategoryCrudService from "../../services/BlogsCategoriesCrud.service";
import config from "../../config";
import { onError } from '../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteBlogsCategory = ({ selectedBlogsCategory, ReLoad, handleCancel }) => {
  const [loading, setLoading]= useState(false)
  const handleDelete = async () => {
    setLoading(true)
    try {
      await BlogsCategoryCrudService.delete(selectedBlogsCategory.id);
      setLoading(false)
      ReLoad(); // Call the onSuccess callback to close the modal and refresh the product list
      onSuccess("Blogs Category deleted successfully!");
    } catch (error) {
      // console.error("Error deleting product:", error);
      setLoading(false)
      onError("An error occurred while deleting the BlogsCategory. Please try again later.");
    }
  };

  return (
    <div className="delete_main">
      <div>
        {/* <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedBlogsCategory.images[0]} alt={selectedBlogsCategory.name}/>
        </div> */}
        <p>Are you sure you want to delete the BlogsCategory "{selectedBlogsCategory.name}"?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {
            loading? <LoadingSpinner/>:"Confirm Delete"
          }
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteBlogsCategory;