import React,{useState} from "react";
import BlogsCrudService from "../../services/BlogsCrud.service";
import config from "../../config";
import { onError } from '../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteBlog = ({ selectedBlog, ReLoad, handleCancel }) => {
  const [loading, setLoading]= useState(false)
  const handleDelete = async () => {
    setLoading(true)
    try {
      await BlogsCrudService.delete(selectedBlog.id);
      setLoading(false)
      ReLoad(); // Call the onSuccess callback to close the modal and refresh the Blog list
      onSuccess("Blog deleted successfully!");
    } catch (error) {
      // console.error("Error deleting Blog:", error);
      setLoading(false)
      onError("An error occurred while deleting the Blog. Please try again later.");
    }
  };

  return (
    <div className="delete_main">
      <div>
        <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedBlog.image} alt={selectedBlog.name}/>
        </div>
        <p>Are you sure you want to delete this Blog ?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {
            loading? <LoadingSpinner/>:"Confirm Delete"
          }
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteBlog;