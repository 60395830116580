import Auth from "../../src/Containers/Auth/index";
import { Layout } from "antd";
import AuthLayout from "../layouts/AuthLayout";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import MainLayout from "../layouts/MainLayout";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Products from "../Containers/Products/Products";
import Banners from "../Containers/Banners/Banners";
import Blogs from "../Containers/Blogs/Blogs";
import Categories from "../Containers/Categories/Categories";
import SubCategories from "../Containers/SubCategories/SubCategories";
import Quotations from "../Containers/Quotations/Quotations";
import BlogsCategories from "../Containers/BlogsCategories/BlogsCategory";

const {Header, Sider, Content} = Layout;

const routes = [
    {
        path: "/",
        component: Auth,
        layout: AuthLayout,
        CustomRoute: UnAuthenticatedRoute,
    },
    // {
    //     path: "/dashboard",
    //     component: Dashboard,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    {
        path: "/categories",
        component: Categories,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/sub-categories",
        component: SubCategories,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/products",
        component: Products,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/banners",
        component: Banners,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/blogs-categories",
        component: BlogsCategories,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/blogs",
        component: Blogs,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/custom-quotes",
        component: Quotations,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    {
        path: "/instant-quotes",
        component: Quotations,
        layout: MainLayout,
        CustomRoute: AuthenticatedRoute,
    },
    // {
    //     path: "/department/list",
    //     component: Departments,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/job-group/list",
    //     component: JobGroup,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/user/list",
    //     component: ManageUsers,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-group/add",
    //     component: AddJobGroup,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-group/edit/:jobId",
    //     component: EditJobGroup,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/contract-type/list",
    //     component: ContractTypes,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-position/list",
    //     component: JobPosition,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-position/add",
    //     component: AddJobPosition,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-position/edit/:positionId",
    //     component: EditJobPosition,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-opening/list",
    //     component: JobOpening,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-opening/add",
    //     component: AddJobOpening,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-opening/edit/:id",
    //     component: EditJobOpening,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-applications/list",
    //     component:JobApplications ,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-applications/view/:viewId",
    //     component:ViewJobApplication,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-profiles/list",
    //     component:JobProfiles ,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/job-profiles/view/:viewId",
    //     component:ViewJobProfiles ,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/notices/list",
    //     component:Notices,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/notices/list/add-notice",
    //     component:AddNotices,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/notices/list/edit-notice/:id",
    //     component:EditNotices,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/shortlists/list",
    //     component:Shortlists,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/shortlists/list/add-shortlist",
    //     component:AddShortlists,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/shortlists/list/edit-shortlist/:id",
    //     component: EditShortlists,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/send-sms/add-sms",
    //     component: AddMessages,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/send-sms/add-bulksms-file",
    //     component: AddBulkMessage,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/news/list",
    //     component: News,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/news/list/add-news",
    //     component:AddNews,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
    // {
    //     path: "/admin/news/edit/:id",
    //     component:EditNews,
    //     layout: MainLayout,
    //     CustomRoute: AuthenticatedRoute,
    // },
]
export default routes
