import React, { useState, useEffect} from "react";
import { Row, Col, Form, Input, Select, Button, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import BannersCrudService from "../../services/BannersCrud.service";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const CreateBanner = ({ ReLoad}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);

  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
        formData.append("image", imageFileList[0].originFileObj)
        // formData.append("name", values.name)
        formData.append("sortOrder", values.sortOrder)

      await BannersCrudService.add(formData);

      setLoading(false);
      onSuccess("Banner created Successfully.");
      ReLoad();
      form.resetFields();
      setImageFileList([])
    } catch (e) {
      onError(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="btns_group">
              <Form.Item label="Image">
                <Upload
                  listType="picture-card"
                  fileList={imageFileList}
                  onPreview={handlePreview}
                  onChange={handleImageChange}
                  beforeUpload={() => false} // Prevent default file upload
                >
                  {imageFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </div>
            {/* <Form.Item name="name" label="Banner Text"
            >
              <Input placeholder="Banner Text" />
            </Form.Item> */}
            <Form.Item name="sortOrder" label="Sort Order"
            >
              <Input type="number" placeholder="Sort Order" min={0}/>
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmltype="submit" loading={loading}>
                  {
                    loading? <LoadingSpinner/>:
                    "Create"
                  }
                </button>
              </Form.Item>
            </div>
          </Form>
          <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default CreateBanner;