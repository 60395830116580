import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SiderData } from "./SiderData";
import SubMenu from "./SubMenu";
import "./Sider.css";
import { useDispatch } from "react-redux";
import { removeLoginUser } from "../../../Store/Auth/authSlice";
import * as BiIcons from "react-icons/bi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Sider = () => {
    const currentLocation = useLocation()
    const dispatch=useDispatch()
    const logout=()=>{
        dispatch(removeLoginUser())
    }
  return (
    <div className="sidebar-main">
      {SiderData?.map((item, index) => {
        if (item.subNav) {
          return (
            <>
              <SubMenu item={item} key={index} />
            </>
          );
        } else {
          return (
            <>
              <NavLink to={item.path} className={`first-menu  ${item.path === currentLocation.pathname ? 'active' : ''}`} key={index}>
                <div  className="first-menu-title">
                  <h3><span>{item.icon}</span>{item.title}</h3>
                </div>
              </NavLink>
            </>
          );
        }
      })}
      <>
        <Link onClick={logout} to="/" className="first-menu logout">
          <div className="first-menu-title" >
            <h3><span><BiIcons.BiLogOutCircle/></span>Logout</h3>
          </div>
        </Link>
      </>
    </div>
  );
};

export default Sider;
