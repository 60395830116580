import React, { useState, useEffect} from "react";
import { Row, Col, Form, Input, Select, Button } from "antd";
import BlogsCategoryCrudService from "../../services/BlogsCategoriesCrud.service";
import { onSuccess } from '../../libs/onSuccess';
import { onError } from '../../libs/errorLib';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const CreateBlogsCategory = ({ ReLoad}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    form.resetFields();
  },[ReLoad])

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        name : values.name,
        seoTitle : values.seoTitle,        
        seoDescription : values.seoDescription,        
        seoKeywords : values.seoKeywords,        
        permalink : values.permalink,        
      }
      await BlogsCategoryCrudService.add(data);

      setLoading(false);
      onSuccess("Blogs Category created Successfully.");
      ReLoad();
      form.resetFields();
    } catch (e) {
      onError(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="name" label="Name"
              rules={[{ required: true, message: "Please enter the name" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item name="seoTitle" label="SEO Title"
              rules={[{ required: true, message: "Please enter the SEO title" }]}
            >
              <Input placeholder="SEO Title" />
            </Form.Item>
            
            <Form.Item name="seoDescription" label="SEO Description"
              rules={[{ required: true, message: "Please enter the SEO description" }]}
            >
              <Input placeholder="SEO Description" />
            </Form.Item>
            
            <Form.Item name="seoKeywords" label="SEO Keywords"
              rules={[{ required: true, message: "Please enter the SEO keywords" }]}
            >
              <Input placeholder="SEO Keywords" />
            </Form.Item>
            
            <Form.Item name="permalink" label="Perma Link"
              rules={[{ required: true, message: "Please enter the perma link" }]}
            >
              <Input placeholder="Perma Link" />
            </Form.Item>
            
            <div className="btns_group">
              <Form.Item>
                <button className="primary_btn" htmltype="submit" loading={loading}>
                  {
                    loading? <LoadingSpinner/>:
                    "Create"
                  }
                </button>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateBlogsCategory;