import React,{useState} from "react";
import { Button } from "antd";
import axios from "axios";
import { onError } from '../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import CategoriesCrudService from "../../services/SubCategoriesCrud.service";
import config from "../../config";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteSubCategories = ({ selectedSubCategory, ReLoad, handleCancel }) => {

  const [loading, setLoading] =useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      await CategoriesCrudService.delete(selectedSubCategory._id);
      setLoading(false)
      ReLoad();
      onSuccess("Sub Category deleted successfully!");
      handleCancel()
    } catch (error) {
      // console.error("Error deleting SubCategory:", error);
      onError("An error occurred while deleting the SubCategory. Please try again later.");
      setLoading(false)
    }
  };

  return (
    <div className="delete_main">
      <div>
        <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedSubCategory?.image} alt={selectedSubCategory?.name}/>
        </div>
        <p>Are you sure you want to delete the category "{selectedSubCategory.name}"?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {loading? <LoadingSpinner/>:"Confirm Delete"}
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteSubCategories;