import React, { useEffect, useState, useRef } from "react";
import { Table, Input, Button, Space, Modal,Row, Col } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { setQuotations } from "../../Store/Quotations/QuotationsSlice";
import QuotationCrudService from "../../services/QuotationCrud.service";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import ViewQuotation from './ViewQuotations';
import moment from "moment";
import { useDispatch } from 'react-redux';
import "./Quotations.css"
import * as FaIcons from 'react-icons/fa'
import { ExportToExcel } from "../../Components/ExportToExcel/ExportToExcel";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Quotations = () => {
  const dispatch = useDispatch();
  const currentLocation = useLocation()
  const [quotePage, setQuotePage] =useState('')
  const [allQuotations, setAllQuotations] = useState([]);
  const [customQuoteArray, setCustomQuoteArray] = useState([]);
  const [instantQuoteArray, setInstantQuoteArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // Modals
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  useEffect(() => {
    if(currentLocation.pathname == '/instant-quotes'){
      setQuotePage('instant')
    }
    else if(currentLocation.pathname == '/custom-quotes'){
      setQuotePage('custom')
    }
  }, [currentLocation]);

  useEffect(() => {
    getallQuotationsList();
  }, []);

  const getallQuotationsList = async () => {
    setLoading(true);
    try {
      const response = await QuotationCrudService.all();
      console.log("Quotations", response)
      const updateQuote=response.quotes.map(quote=>{
        if(quote.file && quote.file!=='null'){
          quote.file=config.IMAGE_BASE_URL + quote.file
        }

        const allowedProperties = ['id', 'name', 'email', 'mobile', 'message', 'createdAt', 'updatedAt'];
        const allProperties = Object.keys(quote);
        const hasOtherProperties = allProperties.some(property => !allowedProperties.includes(property));
        if (hasOtherProperties) {
          quote.quoteType = 'custom'
          return quote
        } else {
          quote.quoteType = 'instant'
          return quote
        }
      })
      
      console.log('updateQuote',updateQuote)
      const customQuotes = updateQuote.filter(item => item.quoteType == 'custom')
      setCustomQuoteArray(customQuotes)
      const instantQuotes = updateQuote.filter(item => item.quoteType == 'instant')
      setInstantQuoteArray(instantQuotes)

      setAllQuotations(response.quotes);
      dispatch(setQuotations(response.quotes))
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => (clearFilters && handleReset(clearFilters), handleSearch(selectedKeys, confirm, dataIndex))}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleView = (record) => {
    setSelectedQuote(record);
    setEditModalVisible(true);
  };
  const handleDelete = (record) => {
    console.log("delete clicked")
  };


  const handleDownload = (file) => {
    const downloadUrl =  file;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = file;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const customColumns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (file) => (
        (file && file!=='null')  ?  <p onClick={() => handleDownload(file)}>
        Attachment <FaIcons.FaCloudDownloadAlt />
      </p>:'No file'
       
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <p onClick={() => handleView(record)} style={{cursor:"pointer"}}><FaIcons.FaRegEye/> View</p>
          {/* <DeleteOutlined title="Delete" onClick={() => handleDelete(record)}/> */}
        </Space>
      ),
    },
  ];
  const instantColumns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <p onClick={() => handleView(record)} style={{cursor:"pointer"}}><FaIcons.FaRegEye/> View</p>
          <p onClick={() => handleDelete(record)} style={{cursor:"pointer"}}><DeleteOutlined/> Delete</p>
          {/* <DeleteOutlined title="Delete" onClick={() => handleDelete(record)}/> */}
        </Space>
      ),
    },
  ];

  const exportInstantQuotes = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => 
      moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
  ];
  const exportCustomQuotes = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile), 
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "width",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "dimension",
      dataIndex: "dimension",
      key: "dimension",
    },
    {
      title: "scale",
      dataIndex: "scale",
      key: "scale",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => 
      moment(createdAt).format("YYYY MMM, DD - hh:mm:ss a"),
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (file, record) => {
        return(  
          
            (file && file!=='null')  ? <a href={config.IMAGE_BASE_URL + file} target="_blank">
            Attachments
          </a>:'No file'
          
        
        )
        },
    },
  ];
  return (
    <>
      <Row className="page_title">
        <h2>{quotePage == 'instant' ? 'Instant Quotes' : `${quotePage == 'custom' && 'Custom Quotes'}`}</h2>
        <div className="btns_group">
          <Button className="primary_btn" onClick={getallQuotationsList} loading= {loading}> Refresh</Button>
          {
            quotePage == 'instant' ?
              <div>
                <ExportToExcel 
                dataSource={instantQuoteArray} 
                columns={exportInstantQuotes} 
                fileName={"instant-quotes" + moment().format('_DD_MM_YY_HH-mm-ss')} />
              </div> :
              quotePage == 'custom' &&
              <div>
                <ExportToExcel 
                dataSource={customQuoteArray} 
                columns={exportCustomQuotes} 
                fileName={"custom-quotes" + moment().format('_DD_MM_YY_HH-mm-ss')} />
              </div>
          }
        </div>
        {/* <button className="primary_btn"  onClick={showModal}>create Sub Category</button>
        <Modal title="Create Sub Category" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer="">
          <CreateCategories
            ReLoad={() => {
                setIsModalOpen(false);
                getallQuotationsList(); // Refresh the subCategories list after create
              }}
          />
        </Modal> */}
      </Row>
      {
        quotePage == 'instant' ?
          <Table
            dataSource={instantQuoteArray}
            columns={instantColumns}
            loading={loading}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          /> :
          quotePage == 'custom' &&
          <Table
            dataSource={customQuoteArray}
            columns={customColumns}
            loading={loading}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
      }

      <Modal
        title="View Quote"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        {/* Edit form */}
        {selectedQuote && (
          <ViewQuotation
            selectedQuote={selectedQuote}
            ReLoad={() => {
              setEditModalVisible(false);
              getallQuotationsList(); // Refresh the product list after editing
            }}
            download= { handleDownload}
            handleCancel = {() => setEditModalVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default Quotations;