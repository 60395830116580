import React,{useState} from "react";
import BannerCrudService from "../../services/BannersCrud.service";
import config from "../../config";
import { onError } from '../../libs/errorLib';
import { onSuccess } from "../../libs/onSuccess";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const DeleteBanner = ({ selectedBanner, ReLoad, handleCancel }) => {
  const [loading, setLoading]= useState(false)
  const handleDelete = async () => {
    setLoading(true)
    try {
      await BannerCrudService.delete(selectedBanner.id);
      setLoading(false)
      ReLoad(); // Call the onSuccess callback to close the modal and refresh the banner list
      onSuccess("Banner deleted successfully!");
    } catch (error) {
      // console.error("Error deleting Banner:", error);
      setLoading(false)
      onError("An error occurred while deleting the Banner. Please try again later.");
    }
  };

  return (
    <div className="delete_main">
      <div>
        <div className="del_img">
          <img src={config.IMAGE_BASE_URL + selectedBanner.image} alt={selectedBanner.name}/>
        </div>
        <p>Are you sure you want to delete this Banner ?</p>
      </div>
      <div className="btns_group">
        <button className="primary_btn" onClick={handleDelete}>
          {
            loading? <LoadingSpinner/>:"Confirm Delete"
          }
        </button>
        <button className="secondary_btn" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteBanner;