import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function Tinymce({setDescription,description=null}) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      setDescription(editorRef.current.getContent());

    }
  };
  return (
    <>
      <Editor
        apiKey='y947dmp1mf8bqyjtj3kwrhyqfv2o7av9nnyfqch2nz8izusx'
        onInit={(evt, editor) => editorRef.current = editor}
        onChange={log}
        initialValue={description}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat |  image table advlist autolink lists link charmap anchor searchplace visualblocks code fullscreen'+
            'insertdatetime media preview help wordcount',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}